
import InstagramLoginCard from '@/components/instagram/InstagramLoginCard.vue';
import InstagramSettingsCard from '@/components/instagram/InstagramSettingsCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { Bot, InstagramPlatformData, SupportedPlatform } from 'ignite360-core';
import _merge from 'lodash.merge';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-platform-instagram',
  components: { InstagramLoginCard, InstagramSettingsCard },
})
export default class ProjectPlatformInstagram extends mixins(ProjectViewMixin) {
  onConnect(data: Partial<InstagramPlatformData>) {
    const newBot: Bot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.Instagram]: data,
      },
    });
    this.$store.commit('bots/updateBot', newBot);
  }

  onDisconnect() {
    const newBot: Bot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.Instagram]: {
          pageId: '',
          pageName: '',
          pageAccessToken: '',
          businessAccountId: '',
        },
      },
    });
    this.$store.commit('bots/updateBot', newBot);
  }
}
